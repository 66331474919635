import React from "react"
import SectionTrigger from 'src/components/SectionTrigger';
import Background from 'src/components/Background';
import SEO from 'src/components/SEO';
import Header from 'src/components/Header';

const BlogPostTemplate = ({ data }) => (
  <div>
    <SEO title="Conversation | Falkon Content" />
    <div className="article">
      <h1>{data.wordpressPost.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.wordpressPost.excerpt }} />
    </div>
  </div>
)

export default BlogPostTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      content
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        alt_text
        source_url
      }
      slug
      title
      wordpress_id
    }
  }
`